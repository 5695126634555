import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p><strong parentName="p">{`“Hollywood”`}</strong></p>
    <p>{`Run 2k (1.24 miles)`}</p>
    <p>{`22-Wall Balls (30/20`}{`#`}{`)`}</p>
    <p>{`22-Muscle Ups`}</p>
    <p>{`22-Wall Balls`}</p>
    <p>{`22-Power Cleans (185/125)`}</p>
    <p>{`22-Wall Balls`}</p>
    <p>{`Run 2K`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this Saturday, November 4th!  There is still time to get a team
together. Truegritcomp.wordpress.com.  If you can`}<strong parentName="p">{`**`}</strong>{` help with
judging or in any other area please let us know.   There will be no
classes or open gym at either location this day.  We will also have no
4:00pm at East or 5:30pm at The Ville in order to set up for True
Grit.***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      